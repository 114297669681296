import React, { useState, useEffect } from "react"
import { Menu, X } from "react-feather"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import { Container } from "../../global"
import {
    Nav,
    NavItem,
    Brand,
    StyledContainer,
    NavListWrapper,
    MobileMenu,
    Mobile,
    ActionsContainer,
} from "./style"

const NAV_ITEMS = [
    { title: "Beneficii", path: "/#features" },
    { title: "Produs", path: "/#product" },
    { title: "Preturi", path: "/#prices" },
    { title: "Blog", path: "/blog" },
]

const Navigation = (props) => {
    const [state, setState] = useState({
        mobileMenuOpen: false,
        hasScrolled: false,
    })

    useEffect(() => {
        window.addEventListener("scroll", handleScroll)

        return function cleanup() {
            window.removeEventListener("scroll", handleScroll)
        }
    })

    const handleScroll = (event) => {
        const scrollTop = window.pageYOffset

        if (scrollTop > 32) {
            setState({ ...state, hasScrolled: true })
        } else {
            setState({ ...state, hasScrolled: false })
        }
    }

    const toggleMobileMenu = () => {
        setState({ ...state, mobileMenuOpen: !state.mobileMenuOpen })
    }

    const closeMobileMenu = () => {
        if (state.mobileMenuOpen) {
            setState({ ...state, mobileMenuOpen: false })
        }
    }

    const getNavAnchorLink = (item) => (
        <AnchorLink to={item.path} onClick={closeMobileMenu}>
            {item.title}
        </AnchorLink>
    )

    const getNavList = ({ mobile = false }) => (
        <NavListWrapper mobile={mobile}>
            {NAV_ITEMS.map((navItem) => (
                <NavItem key={navItem.title}>{getNavAnchorLink(navItem)}</NavItem>
            ))}
        </NavListWrapper>
    )

    const openApp = () => {
        if (typeof window !== `undefined`) {
            const url = `${process.env.GATSBY_END_USER_URL}`
            window.open(url, "_blank").focus()
        }
    }

    const { mobileMenuOpen } = state.mobileMenuOpen

    return (
        <Nav {...props} scrolled={state.hasScrolled}>
            <StyledContainer>
                <Brand>
                    <AnchorLink to="/#top" onClick={closeMobileMenu}>
                        eConta
                    </AnchorLink>
                </Brand>
                <Mobile>
                    <button
                        onClick={toggleMobileMenu}
                        style={{ color: "black", background: "none" }}
                    >
                        {state.mobileMenuOpen ? (
                            <X size={24} alt="close menu" />
                        ) : (
                            <Menu size={24} alt="open menu" />
                        )}
                    </button>
                </Mobile>

                <Mobile hide>{getNavList({})}</Mobile>
                <ActionsContainer>
                    {process.env.GATSBY_END_USER_URL && <button onClick={() => openApp()}>Autentificare</button>}
                </ActionsContainer>
            </StyledContainer>
            <Mobile>
                {mobileMenuOpen && (
                    <MobileMenu>
                        <Container>{getNavList({ mobile: true })}</Container>
                    </MobileMenu>
                )}
            </Mobile>
        </Nav>
    )
}

export default Navigation
