import React from "react"
import styled from "styled-components"
import { Link, graphql } from "gatsby"

import { Container } from "../../components/global"
import Layout from "../../components/common/layout/layout"
import SEO from "../../components/common/layout/seo"
import Navigation from "../../components/common/navigation/navigation"
import Footer from "../../components/sections/footer"

const BlogPage = ({ data }) => (
  <Layout>
    <SEO title="Blog" />
    <Navigation />
    <StyledContainer>
      <HeaderWrapper>
        <Subtitle>Blog</Subtitle>
        {data.allMdx.nodes.map((node) => (
          <article key={node.id}>
            <Link to={`/blog/${node.slug}`}>{node.frontmatter.title}</Link>
            <p>Posted: {node.frontmatter.date}</p>
          </article>
        ))}
      </HeaderWrapper>
    </StyledContainer>
    <Footer />
  </Layout>
)

export default BlogPage

export const query = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        frontmatter {
          date(formatString: "MMMM D, YYYY")
          title
        }
        id
        slug
      }
    }
  }
`

const StyledContainer = styled(Container)``

const HeaderWrapper = styled.header`
  padding: 160px 0 80px 0;
  position: relative;
  @media (max-width: ${(props) => props.theme.screen.md}) {
  }
`
const Subtitle = styled.h5`
  font-size: 16px;
  color: ${(props) => props.theme.color.accent};
  letter-spacing: 0px;
  margin-bottom: 16px;
`
